<template>
  <div v-if="$props.item && $props.item.type == 'PDF'">
    <div class="container">
      <div class="row">
        <div class="col-sm pdf-display">
          <pdf-viewer
            :key="$props.item.id"
            :getDocumentHandler="getDocumentHandler"
            @onLastPageVisualized="lastPageVisualized"
            :loading="this.loading"
          >
            <template slot="left-button">
              <b-button
                v-show="$props.item && $props.item.lastStep == false"
                class="my-2 left-button-next"
                :disabled="$props.item && $props.item.status != 'FINISHED'"
                @click="() => onClickNextStep()"
                variant="primary"
              >
                Ir para próxima etapa
              </b-button>
            </template>
          </pdf-viewer>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PdfViewer from "../../../components/pdf-viewer/PdfTrainingViewer.vue";
import {
  getDocument,
} from "../../../services/training/trainingService.js";

export default {
  props: ["onFinishedStep", "onStartStep", "onNextStep", "item", "training"],
  components: { PdfViewer },
  data() {
    return {
      downloadedFile: null,
      loading: true,
    };
  },
  methods: {
    getLink() {
      const file = this.$props.item.filesAdditional.filter((fa) => fa.principal)[0];
      return { "trainingName": this.$props.training.title, "fileName": file.fileName }
    },
    async getDocumentHandler() {
      this.loading = true;
      await this.$props.onStartStep(this.$props.item);

      const toReturn = await getDocument(this.$props.training.title, this.getLink().fileName)
      .then((response) => {
        return new Promise((resolve) => {
            this.loading = false;
            this.downloadedFile = response.documentBase64
            resolve(response.documentBase64)
          })
      })
      return toReturn;
    },
    async onClickNextStep() {
      await this.$props.onFinishedStep(this.$props.item, true);
      await this.$props.onNextStep();
    },
    async lastPageVisualized() {
      await this.$props.onFinishedStep(this.$props.item, false);
      this.$props.item.status = "FINISHED";
    },
  },
};
</script>
<style lang="scss" scoped>
.left-button-next {
  margin-right: 40px;
  flex-direction: column;
}
</style>
