<template>
  <div class="principal-backbeat">
    <CustomVisualizationLayout
      :leftInfoOne="leftInfoOne"
      :leftActionOne="this.leftActionOne"
      :leftInfoTwo="leftInfoTwo"
      :leftActionTwo="this.leftActionTwo"
    >
      <template slot="contentOne">
        <p class="custom-p5 mb-4">
          O backbeat é o programa de remuneração da Unimed Grande Florianópolis
          que tem como objetivo valorizar o trabalho do médico cooperado,
          através de uma remuneração meritocrática, paga sob os valores dos
          honorários de consultas e procedimentos realizados.
        </p>
        <p class="custom-p5 mb-4">
          No backbeat os esforços coletivos e a qualidade assistencial vão andar
          lado a lado e, juntos, definir o valor da sua remuneração variável.
          Para obter os ganhos extras de forma integral, é necessário atingir os
          critérios dessas duas esferas de atuação.
        </p>
        <p class="custom-p5">
          Para ser elegível, é necessário que você não tenha recebido nenhuma
          infração relacionada ao não cumprimento das normas internas da
          cooperativa nos últimos seis meses, além de atender todos os
          indicadores idealizados para o programa.
        </p>
        <div class="div-video">
          <video
            id="myVideo"
            width="100%"
            height="307"
            controls
            @ended="onFinishedVideo()"
            @play="onPlay()"
          >
            <source
              src="https://firebasestorage.googleapis.com/v0/b/ugf-prod-plataforma-cooperado/o/others%2F01%20-%20Backbeat.mp4?alt=media"
              type="video/mp4"
            />
          </video>
        </div>
        <div class="help--internal mt-5" @click="onClickBackbeat()">
          CONFIRA OS RESULTADOS DO SEU BACKBEAT
          <img
            class="mdi-chevron-right"
            src="../../../assets/images/mdi-chevron-right.svg"
          />
        </div>
      </template>
      <template slot="contentTwo">
        <div class="content-two">
          <div class="custom-div-cards">
            <div class="custom-div-card custom-div-card--one mb-4">
              <p class="custom-card-title">Indice de Sinistralidade</p>
              <p class="custom-card-body">
                A meta coletiva está diretamente ligada ao índice de
                sinistralidade acumulado do ano vigente. Caso esteja inferior a
                82%, todos os cooperados recebem 2% adicionais.
              </p>
            </div>
            <div class="custom-div-card custom-div-card--two mb-4">
              <p class="custom-card-title">Desempenho Administrativo</p>
              <p class="custom-card-body">
                Os indicadores administrativos estão relacionados aos deveres do
                médico (a) como membro cooperado (a) da Unimed Grande
                Florianópolis. Ao cumprir esses requisitos, é possível
                incrementar o pagamento em 1%.
              </p>
            </div>
            <div class="custom-div-card custom-div-card--tree mb-4">
              <p class="custom-card-title">Eficiência Técnica</p>
              <p class="custom-card-body">
                Corresponde aos indicadores específicos para cada especialidade.
                Esse conjunto foi definido em conjunto com cada um dos comitês
                da cooperativa, de forma a garantir critérios justos de acordo
                com a realidade de cada médico (a). Compõe 3% do total do
                backbeat.
              </p>
            </div>
            <div class="custom-div-card custom-div-card--for">
              <p class="custom-card-title">Pacote Tecnológico</p>
              <p class="custom-card-body">
                Oferecemos aos cooperados ferramentas tecnológicas compatíveis
                com as tendências de mercado mais inovadoras, para aprimorar o
                dia a dia nos consultórios. Além de ganhar em agilidade, o
                médico (a)também recebe 2% de bonificação por isso!
              </p>
            </div>
          </div>
        </div>
      </template>
    </CustomVisualizationLayout>
  </div>
</template>

<script>
import RoutesPaths from "../../../router/router-structure/routes-paths";
import CustomVisualizationLayout from "../components/visualization/CustomVisualizationLayout.vue";
import { saveShowBackbeat } from "../../../services/backbeat/route-page";
import { Firebase } from "../../../services/firebase/log-firebase";

export default {
  components: {
    CustomVisualizationLayout,
  },
  mounted() {
    this.initLog();
    saveShowBackbeat();
  },
  data: function() {
    return {
      leftActionOne: {
        text: "Saiba mais sobre o Backbeat",
        onClick: this.leftActionClickHandler,
      },
      leftInfoOne: "",
      leftActionTwo: {
        text: "Os indicadores",
        onClick: null,
      },
      leftInfoTwo: "",
      firebase: null,
    };
  },
  methods: {
    leftActionClickHandler() {
      this.$router.push({
        path: RoutesPaths.financial.backbeat(),
        replace: true,
      });
    },
    onClickBackbeat() {
      this.$router.push({
        path: RoutesPaths.financial.backbeat(),
        replace: true,
      });
    },
    initLog() {
      this.firebase = new Firebase();
      this.firebase.addInfoLog(
        "Acessou a tela de saiba mais sobre o Backbeat.",
        "BACKBEAT"
      );
    },
    onPlay() {
      if (this.firebase !== null)
        this.firebase.addInfoLog(
          "Iniciou o video da tela saiba mais sobre o Backbeat.",
          "BACKBEAT"
        );
    },
    onFinishedVideo() {
      if (this.firebase !== null)
        this.firebase.addInfoLog(
          "Assistiu o video da da tela saiba mais sobre o Backbeat até o fim.",
          "BACKBEAT"
        );
    },
  },
};
</script>

<style lang="scss" scoped>
.principal-backbeat {
  background-color: var(--background);
  height: 100%;
  margin-left: 5%;
  margin-right: 7%;
}

.custom-p5 {
  font-size: multiplier-default-font-size(3); // 16px
  color: var(--grey-1);
  opacity: 1;
}

.content-two {
  display: flex;
  flex-flow: column;
  height: 100vh;
  padding-left: 0rem !important;
  width: 100% !important;
}

.div-video {
  height: 30.7rem;
  width: 100%;
  background-color: white;
  margin-top: 7rem;
}

.custom-div-cards {
  width: 100%;
}

.custom-div-card {
  // height: 16.8rem;
  height: auto;
  width: 100%;

  background-color: white;
  border-radius: 1rem;

  padding-top: 1rem;
  padding-left: 1.5rem;
  padding-right: 3rem;
  padding-bottom: 2rem;
}

.custom-card-title {
  font-size: multiplier-default-font-size(3); // 16px
  color: var(--primary);
  margin-bottom: 0.5rem;
}

.custom-card-body {
  font-size: multiplier-default-font-size(1); //12px
  color: var(--grey-1);
}
</style>
