<template>
  <div>
    <FormLayout
      :tabs="this.tabs"
      :leftAction="this.leftAction"
      :onChangeTab="onChangeTabIndex"
    ></FormLayout>
  </div>
</template>

<script>
import Vue from "vue";
import FormLayout from "../../../layouts/FormLayout.vue";
import RoutesPaths from "../../../router/router-structure/routes-paths";

Vue.component("general-information-certification", () =>
  import("./GeneralInformationCertificationForm.vue")
);

Vue.component("links-training", () =>
  import("./LinksTrainingForm.vue")
);

export default {
  components: {
    FormLayout,
  },
  data: function() {
    return {
      currentTabIndex: 0,
      leftAction: {
        text: "Novo certificado",
        onClick: this.leftActionClickHandler,
      },
      leftLinkNavigation: this.leftHistoryNavigation,

      tabs: [
        {
          title: "Informações Gerais",
          formComponent: "general-information-certification",
        },
        {
          title: "Vínculos com Treinamentos",
          formComponent: "links-training",
        },
      ],
    };
  },
  methods: {
    onChangeTabIndex(newValue) {
      this.currentTabIndex = newValue;
    },
    leftActionClickHandler() {
      this.$router.push(RoutesPaths.contentTrainingManagement.rootName());
    },
    leftHistoryNavigation() {
      this.$router.push({
        path: RoutesPaths.contentTrainingManagement.rootName(),
        params: {
          serviceType: this.tabs[this.currentTabIndex].serviceType,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>
