export function formatDateToBootstrapPicker(dateString) {
  if (!dateString) return;
  var parts = dateString.split("/");
  let date = new Date(parts[2], parts[1] - 1, parts[0]);
  return date.toISOString().split("T")[0];
}

export function formatStringHyphenToDate(dateString) {
  if (!dateString) return;
  var parts = dateString.split("-");
  let date = new Date(parts[0], parts[1] - 1, parts[2]);
  return date;
}

export function dateToTime(dateString) {
  if (!dateString) return;
  return new Date(dateString).toLocaleTimeString();
}

export function dateToLocaleDateString(dateString) {
  if (!dateString) return;
  return new Date(dateString).toLocaleDateString();
}

export function adicionaZero(numero) {
  if (numero <= 9) return "0" + numero;
  else return numero;
}

export function returnDateFormat(dataString) {
  if (!dataString) return "";
  let dataAtual = new Date(dataString.replace(/-/g, "/"));

  let dataAtualFormatada =
    adicionaZero(dataAtual.getDate().toString()) +
    "/" +
    adicionaZero(dataAtual.getMonth() + 1).toString() +
    "/" +
    dataAtual.getFullYear();

  return dataAtualFormatada;
}

export function formatDate(date) {
  let dia = date.getDate();
  let mes = [
    "janeiro",
    "fevereiro",
    "março",
    "abril",
    "maio",
    "junho",
    "julho",
    "agosto",
    "setembro",
    "outubro",
    "novembro",
    "dezembro",
  ][date.getMonth()];
  let ano = date.getFullYear();

  return `${dia} de ${mes} de ${ano}`;
}

export function getMonth(month) {
  switch (month) {
    case "JANEIRO":
      return "01";
    case "FEVEREIRO":
      return "02";
    case "MARÇO":
      return "03";
    case "ABRIL":
      return "04";
    case "MAIO":
      return "05";
    case "JUNHO":
      return "06";
    case "JULHO":
      return "07";
    case "AGOSTO":
      return "08";
    case "SETEMBRO":
      return "09";
    case "OUTUBRO":
      return "10";
    case "NOVEMBRO":
      return "11";
    case "DEZEMBRO":
      return "12";
    default:
      null;
  }
}

export function getMonthNumber(month) {
  if (month == null || month == undefined) return null;
  if (month.length > 2) {
    return month.substring(month.length - 2);
  }
  return month.padStart(2, "0");
}

export function allMonth() {
  return [
    "JAN",
    "FEV",
    "MAR",
    "ABR",
    "MAI",
    "JUN",
    "JUL",
    "AGO",
    "SET",
    "OUT",
    "NOV",
    "DEZ",
  ];
}

export function dateFormatMonthToStringAndYearCurrent(monthNumber, year) {
    let mes = [
      "Janeiro",
      "Fevereiro",
      "Março",
      "Abril",
      "Maio",
      "Junho",
      "Julho",
      "Agosto",
      "Setembro",
      "Outubro",
      "Novembro",
      "Dezembro",
    ][monthNumber];
    return mes + "/" + year;
} 
export function isValidDate(dateString) {
  let pattern = /^\d{4}-\d{2}-\d{2}$/;
  return pattern.test(dateString);
}

export function dateFormat(dateString) {
  if (dateString == null || dateString == undefined) return null;
  if (isValidDate(dateString)) {
    const date = dateString.split("-");
    return date[2] + "/" + date[1] + "/" + date[0];
  }
  return null;
}

export function stringToDate(dateString) {

  if (!dateString) return null;
  if (!dateString.includes("T")) return null;

  var splitDate = dateString.split("T");
  var date = splitDate[0];
  var time = splitDate[1];

  var dates = date.split("-");
  var times = time.split(".")[0].split(":")

  return new Date(dates[0], dates[1] - 1, dates[2], times[0], times[1], times[2]);
}

