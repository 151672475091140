export function getFunctionalityName(name) {
    const funcNames = {
        "/home":"Acesso a Home",
        "/register":"Menu cadastro",
        "/financial/analytics":"Analiticas",
        "/financial/backbeat":"Visualizar os dados do backbeat",
        "/financial/sustainability-fund":"Fundo de sustentabilidade",
        "/login":"Logins na plataforma",
        "/password-recovery":"Recuperação de senha",
        "/declarations":"Pagina de declarações",
        "/declarations/cooperative-declaration":"Declaração de médico cooperado",
        "/financial/review-glosses":"Solicitação de revisão de glosas",
        "/panel-economic":"Painel econômico",
        "/financial/income-history":"Histórico de notas fiscais cadastradas",
        "/register/personal-information":"Cadastro Informações pessoais",
        "/register/medical-specialities":"Cadastro de especialidade médica",
        "/register/attendence-place":"Cadastro - Local de atendimento",
        "/request-history":"Histórico de solicitações",
        "/register/payment-options":"Cadastro de direcionamento de pagamentos",
        "/change":"Redefinição de senha",
        "/benefit":"Tela de benefícios",
        "/faq":"Acesso ao FAQ",
        "/financial":"Menu Financeiro",
        "/financial/quota-statement":"Extrato de cota capital",
        "/virtual-office":"Consultório virtual",
        "/institutional-page":"Página institucional",
        "/documents-atas":"Página de documentos",
        "/register/tax-license":"Atualização cadastral - Impostos (iss e inss)",
        "/benefit/health-plan":"Informações plano de saúde cooperado",
        "/benefit/absence":"Afastamento temporário",
        "/financial/report-income":"Informe de Rendimentos",
        "/declarations/inss":"Solicitação de declarações de INSS",
        "/benefit/benefactor":"Tela de solicitações de benemérito",
        "/funeral-assistance":"Informações sobre Aux. Funeral",
        "/declarations/ltcatpp":"Solciitação de LTCAT",
        "/benefit/temporary-assistance":"Auxilio temporário",
        "/declarations/others":"Solcitação de outras declarações",
        "/use-term/accepted":"Termo de uso",
        "/declarations/iss":"Solicitação de declarações de ISS",
        "/benefit/other-solicitation":"Serviços de outras solicitações",
        "/financial/report-voxis":"Relatório de glosas Voxis",
        "/training-page":"Pagina de treinamento",
        "/empty-home":"Acesso Admin",
        "/financial/backbeat-know-more":"Backbeat - Saiba Mais",
        "Assistiu vídeo backbeat":"Assistiu vídeo backbeat",
        "Iniciou o video da tela saiba mais sobre o Backbeat.":"Iniciou vídeo backbeat",
        "Acessou a tela de saiba mais sobre o Backbeat.": "Backbeat - Acessou tela saiba mais",
        "/admin/training-management-form/": "Acessou a tela de gerenciamento de treinamento.",
        "/admin/certification-management/": "Acessou a tela de gerenciamento de certificado.",
        "/admin/portal-views-report": "Acessou a tela de Relatório de visualizações do Portal.",
    }
    const matchedKey = Object.keys(funcNames).find(key => name.startsWith(key));
    const result = matchedKey ? funcNames[matchedKey] : name;
    return result;
}