<template>
  <div class="training-container">
    <div
      :class="!!this.stepSelected ? 'invisible' : 'null'"
      class="training-img"
    ></div>
    <TableVisualizationLayout
      :leftAction="leftAction"
      :showTable="false"
      class="custom-view-training"
    >
      <template slot="headerContent">
        <div class="content-all">
          <div class="content-tab">
            <b-tabs
              class="custom-navbar"
              content-class="mt-4"
              align="center"
              @input="onTabChange"
            >
              <b-tab title="Disponíveis" active
                ><TabTraining
                  :onStepClick="onStepClick"
                  :currentStep="stepSelected"
                  ref="childTrainingRunning"
                  :trainings="this.trainingsRunning"
                  :key="this.componentKeyRunning"
              /></b-tab>
              <b-tab title="Histórico de treinamentos"
                ><TabTraining
                  :onStepClick="onStepClick"
                  :currentStep="stepSelected"
                  ref="childTrainingFinished"
                  :trainings="this.trainingsFinished"
                  :key="this.componentKeyFinished"
              /></b-tab>
            </b-tabs>
          </div>
          <div class="content-step ">
            <Inicial v-if="!stepSelected" />
            <Video
              :item="stepSelected"
              :onNextStep="onNextStep"
              :onFinishedStep="onFinishedStep"
              :onStartStep="onStartStep"
            />
            <Pdf
              :item="stepSelected"
              :onNextStep="onNextStep"
              :onFinishedStep="onFinishedStep"
              :onStartStep="onStartStep"
              :training="trainingSelected"
            />
            <Form
              :item="stepSelected"
              :onNextStep="onNextStep"
              :onFinishedStep="onFinishedStep"
              :onStartStep="onStartStep"
            />
            <Certificate
              :item="stepSelected"
              :onNextStep="onNextStep"
              :onFinishedStep="onFinishedStep"
              :onStartStep="onStartStep"
            />
          </div>
        </div>
      </template>
    </TableVisualizationLayout>
  </div>
</template>

<script>
import TableVisualizationLayout from "../../../layouts/TableVisualizationLayout.vue";
import TabTraining from "./tab-training.vue";
import RoutesPaths from "../../../router/router-structure/routes-paths";
import {
  getStatusTrainingRunning,
  getStatusTrainingFinished,
  updateStatusTraining,
} from "../../../services/training/trainingService.js";
import Inicial from "../components/inicial.vue";

import Form from "../components/form.vue";
import Video from "../components/video.vue";
import Pdf from "../components/pdf.vue";
import Certificate from "../components/certificate.vue";
export default {
  components: {
    TableVisualizationLayout,
    TabTraining,
    Inicial,
    Video,
    Form,
    Pdf,
    Certificate,
  },
  created() {
    this.onGetTrainings();
  },
  data() {
    return {
      leftAction: {
        text: "Treinamentos",
        onClick: this.leftActionClickHandler,
      },
      componentKeyRunning: 0,
      componentKeyFinished: 0,
      trainingsRunning: [],
      trainingsFinished: [],
      stepSelected: null,
      trainingSelected: null,
    };
  },
  methods: {
    onGetNameTrainig() {
      this.trainingsRunning.some((tr) => {
        if (tr.id == this.stepSelected.idTraining) {
          this.trainingSelected = tr;
        }
      });
      this.trainingsFinished.some((tr) => {
        if (tr.id == this.stepSelected.idTraining) {
          this.trainingSelected = tr;
        }
      });
    },
    getUser() {
      return this.$store.getters.getUserInformation?.name;
    },
    onGetTrainings() {
      getStatusTrainingRunning().then((result) => {
        this.trainingsRunning = result;
        this.componentKeyRunning += 1;
      });
      getStatusTrainingFinished().then((result) => {
        this.trainingsFinished = result;
        this.componentKeyFinished += 1;
      });
    },
    leftActionClickHandler() {
      this.$router.push({
        path: RoutesPaths.institutionalPage.rootName(),
        replace: true,
      });
    },
    async onStartStep(step) {
      if (step.status != "FINISHED" && !this.$store.getters.isAdmin) {
        await updateStatusTraining(
          step.id,
          step.idTraining,
          "STARTED",
          this.getUser()
        );
      }
    },
    async onFinishedStep(step, updateStatusDB) {
      if (updateStatusDB && !this.$store.getters.isAdmin) {
        if (updateStatusDB) {
          await updateStatusTraining(
            step.id,
            step.idTraining,
            "FINISHED",
            this.getUser()
          );
        }
        if (step.lastStep == true) {
          this.onGetTrainings();
          this.stepSelected = null;
        }
      }
    },
    onNextStep() {
      const nextRunning = this.trainingsRunning.some((tr) => {
        return tr.id == this.stepSelected.idTraining;
      });
      const nextFinished = this.trainingsFinished.some((tr) => {
        return tr.id == this.stepSelected.idTraining;
      });
      if (nextRunning)
        this.$refs.childTrainingRunning.onNextStep(this.stepSelected);
      if (nextFinished)
        this.$refs.childTrainingFinished.onNextStep(this.stepSelected);
    },
    onTabChange() {
      this.stepSelected = null;
    },
    onStepClick(step, steps) {
      const beforeStep = [...steps]
        .sort((a, b) => b.order - a.order)
        .find((item) => item.order < step.order);
      if (
        step.order == 0 ||
        beforeStep?.status == "FINISHED" ||
        this.$store.getters.isAdmin
      ) {
        this.stepSelected = step;
        this.onGetNameTrainig();
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.content-step {
  margin-top: 3rem;
  width: 100%;
  padding: 0 px;
}
.content-tab {
  width: 100%;
}
.align-step-center {
  display: flex;
  justify-content: center;
  align-items: center;
}
.content-all {
  display: flex;
  flex-flow: row wrap;
  width: 100%;
  flex-direction: column;
}
.training-img {
  height: 100%;
  width: 100%;
  background-repeat: no-repeat;

  visibility: hidden;

  background-size: 48rem 38rem;
  background-position: 90% 90%;
  background-image: url("../../../assets/images/img-treinamento.svg");
}

.training-container {
  float: left;
  position: relative;
  height: 100%;
  width: 100%;
  background-color: var(--background);
}

.custom-view-training {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  margin: auto;
}

@media (min-width: 992px) {
  .content-step {
    margin-top: 0rem;
    width: 70%;
  }

  .content-all {
    margin-top: 2rem;
    flex-direction: row;
  }

  .content-tab {
    width: 30%;
  }

  .training-img {
    visibility: visible;
  }
}

.visible {
  visibility: visible;
}
.invisible {
  visibility: hidden;
}
</style>
<style lang="scss">
@media (max-width: 991px) {

  .training-container {  
  
    .title-custom {
      display: flex;
    }

    .navigation {
      margin: auto;
    }  

    .row .header-content .margin-content {
      margin-left: 0 !important;
    }
  }
}
</style>