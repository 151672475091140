import router from "../../../router";
import RoutesPaths from "../../../router/router-structure/routes-paths";
import LinkType from "../../../static-data/Links.js";
import { isMobile } from "../../../utils/mobile/mobile";
import store from "../../../store/index";
import { showBackbeat } from "../../../services/backbeat/route-page";
import { getNewByName } from "../../../utils/news/news-utils";
import { saveNewness, validateShowNewness } from "../../../services/newness/newness-new.js";

const removeNewIfLastLevel = (featureName) => {
  const checkValidateShowNewness = validateShowNewness();
    if (checkValidateShowNewness && checkValidateShowNewness == true) {
    const currentNew = getNewByName(featureName);
    if (currentNew && currentNew?.lastLevel) {
      saveNewness(currentNew?.idConfigurationNewness);
      store.dispatch(
        "removeNewness",
        currentNew?.idConfigurationNewness
      );
    }
  }
}
export const dropdownHome = () => {
  return [
    {
      label: "Analítica",
      featureName: "HOME_ANALYTIC_MENU",
      action: function () {
        removeNewIfLastLevel(this.featureName)
        router.push({
          path: RoutesPaths.financial.analytics(),
          replace: true,
        });
      },
    },
    {
      label: "Meu Clube Unimed",
      featureName: "HOME_CLUB_MENU",
      action: function () {
        removeNewIfLastLevel(this.featureName)
        window.open(LinkType.CLUB, "_blank");
      },
    },
    {
      label: "Painel Econômico",
      featureName: "HOME_INSTITUTIONAL_MENU",
      action: function () {
        removeNewIfLastLevel(this.featureName)
        router.push({
          path: RoutesPaths.institutionalPage.panelEconomic(),
          replace: true,
        });
      },
    },
    {
      label: "Transparência e Comprometimento",
      featureName: "HOME_TRANSPARENCY_AND_COMMITMENT_MENU",
      action: function () {
        removeNewIfLastLevel(this.featureName)
        window.open(LinkType.TRANSPARENCY_AND_COMMITMENT, "_blank");
      },
    },
    {
      label: "Formulário e Termo de Consetimento Cirúrgico",
      featureName: "HOME_SURGICAL_CONSENT_MENU",
      action: function () {
        removeNewIfLastLevel(this.featureName)
        window.open(LinkType.SURGICAL_CONSENT, "_blank");
      },
    },
    {
      label: "Histórico de Notas Fiscais",
      featureName: "HOME_INVOICE_HISTORY_MENU",
      action: function () {
        removeNewIfLastLevel(this.featureName)
        router.push({
          path: RoutesPaths.financial.incomeHistory(),
          replace: true,
        });
      },
    },
    {
      label: "Conexão Cooperado",
      featureName: "HOME_CONNECTION_MENU",
      action: function () {
        removeNewIfLastLevel(this.featureName)
        window.open(LinkType.CONNECTION, "_blank");
      },
    },
    {
      label: "Outras Solicitações",
      featureName: "HOME_OTHER_SOLICITATION_MENU",
      action: function () {
        removeNewIfLastLevel(this.featureName)
         router.push({
          path: RoutesPaths.benefit.otherSolicitation(),
          replace: true,
        });
      },
    },
  ]
}

export const dropdownInstitucional = () => {
  let list = [
   {
      label: "Organograma",
      featureName: "INSTITUCIONAL_ORGANIZATIONAL_CHART",
      action: function () {
        removeNewIfLastLevel(this.featureName)
        router.push({
          path: RoutesPaths.institutionalPage.rootName(),
          query: { openLink: "true" },
          replace: true,
        });
      },
      showMobile: false,
    },
    {
      label: "Nossa história",
       featureName: "INSTITUCIONAL_OUR_STORY_MENU",
      action: function () {
        removeNewIfLastLevel(this.featureName)
        window.open(LinkType.OUR_STORY, "_blank");
      },
      showMobile: false,
    },
    {
      label: "Reconhecimentos",
      featureName: "INSTITUCIONAL_RECOGNITIONS_MENU",
      action: function () {
        removeNewIfLastLevel(this.featureName)
        window.open(LinkType.COLLECTIONS, "_blank");
      },
      showMobile: false,
    },
    {
      label: "Transparência e Comprometimento",
      featureName: "INSTITUCIONAL_TRANSPARENCY_AND_COMMITMENT_MENU",
      action: function () {
        removeNewIfLastLevel(this.featureName)
        window.open(LinkType.TRANSPARENCY_AND_COMMITMENT, "_blank");
      },
      showMobile: false,
    },
    {
      label: "Nosso jeito é cuidar",
      featureName: "INSTITUCIONAL_OUR_WAY_OF_CARE_MENU",
      action: function () {
        removeNewIfLastLevel(this.featureName)
        window.open(LinkType.OUR_WAY_OF_CARE, "_blank");
      },
      showMobile: false,
    },
    {
      label: "Documentos e Atas",
       featureName: "INSTITUCIONAL_DOCUMENT_ATAS_MENU",
      action: function () {
        removeNewIfLastLevel(this.featureName)
        router.push({
          path: RoutesPaths.documentsAtasPage.documentsAtas(),
          replace: true,
        });
      },
      showMobile: true,
    },
    {
      label: "Painel econômico",
      featureName: "INSTITUCIONAL_ECONOMIC_DASHBOARD_MENU",
      action: function () {
        removeNewIfLastLevel(this.featureName)
        router.push({ path: RoutesPaths.institutionalPage.panelEconomic(), replace: true });
      },
      showMobile: true,
    },
    {
      label: "Treinamentos",
      featureName: "INSTITUCIONAL_TRAINING_MENU",
      action: function () {
        removeNewIfLastLevel(this.featureName)
        router.push({
          path: RoutesPaths.trainingPage.rootName(),
          replace: true,
        });
      },
      showMobile: true,
    },
      {
      label: "ROL de cobertura ANS + DUTs",
      featureName: "INSTITUCIONAL_ANT_DUTS_MENU",
        action: function () {
        removeNewIfLastLevel(this.featureName)
        window.open(LinkType.ANT_DUTS, "_blank");
      },
      showMobile: true,
    },
       {
      label: "Novas Tecnologias",
      featureName: "INSTITUCIONAL_NEW_TECNOLOGY_MENU",
        action: function () {
        removeNewIfLastLevel(this.featureName)
        window.open(LinkType.NEW_TECNOLOGY, "_blank");
      },
      showMobile: true,
    },
  ];
  if (isMobile()) {
    return list.filter((c) => c.showMobile);
  }
    return list;
};

export const dropdownCadastro = () => {
  return [
    {
      label: "Informações pessoais",
      featureName: "REGISTER_PERSONAL_INFORMATION_MENU",
      action: function () {
        removeNewIfLastLevel(this.featureName)
        router.push({
          path: RoutesPaths.register.personalInformation(),
          replace: true,
        });
      },
    },
    {
      label: "Especialidades médicas",
      featureName: "REGISTER_MEDICAL_SPECIALITIES_MENU",
      action: function () {
        removeNewIfLastLevel(this.featureName)
        router.push({
          path: RoutesPaths.register.medicalSpecialities(),
          replace: true,
        });
      },
    },
    {
      label: "Locais de atendimento",
      featureName: "REGISTER_ATTENDENCE_PLACE_MENU",
      action: function () {
        removeNewIfLastLevel(this.featureName)
        router.push({
          path: RoutesPaths.register.attendencePlace(),
          replace: true,
        });
      },
    },
    {
      label: "Meios de pagamento",
      featureName: "REGISTER_PAYMENT_OPTIONS_MENU",
      action: function () {
        removeNewIfLastLevel(this.featureName)
        router.push({
          path: RoutesPaths.register.paymentOptions(),
          replace: true,
        });
      },
    },
    {
      label: "Impostos e alvará",
      featureName: "REGISTER_TAX_LICENSE_MENU",
      action: function () {
        removeNewIfLastLevel(this.featureName)
        router.push({ path: RoutesPaths.register.taxLicense(), replace: true });
      },
    },
  ];
};

export const dropdownDeclaracoes = () => {
  return [
    {
      label: "Declaração médico cooperado",
      featureName: "DECLARATION_COOPERATIVE_DECLARATION_MENU",
      action: function () {
        removeNewIfLastLevel(this.featureName)
        router.push(RoutesPaths.declarations.cooperativeDeclaration());
      },
    },
    {
      label: "Solicite declaração de contribuição pela UGF",
      featureName: "DECLARATION_INSS_MENU",
      action: function () {
        removeNewIfLastLevel(this.featureName)
        router.push(RoutesPaths.declarations.inss());
      },
    },
    {
      label: "Solicite declaração de retenção de ISS",
      featureName: "DECLARATION_ISS_MENU",
      action: function () {
        removeNewIfLastLevel(this.featureName)
        router.push(RoutesPaths.declarations.iss());
      },
    },
    {
      label: "Declaração LTCAT/PPP",
      featureName: "DECLARATION_LTCAT_MENU",
      action: function () {
        removeNewIfLastLevel(this.featureName)
        router.push(RoutesPaths.declarations.ltcatpp());
      },
    },
    {
      label: "Solicitar outras declarações",
      featureName: "DECLARATION_OTHERS_MENU",
      action: function () {
        removeNewIfLastLevel(this.featureName)
        router.push(RoutesPaths.declarations.others());
      },
    },
  ];
};

export const dropdownConsultorioVirtual = () => {
  return [
    {
      label: "Telemedicina",
      featureName: "VIRTUAL_MEDICINE_MENU",
      isDisabled: () => {  return store.getters.isAdmin }, 
      action: function () {
        removeNewIfLastLevel(this.featureName)
        router.push({
          path: RoutesPaths.virtualOffice.rootName(),
          query: { openLink: "true" },
          replace: true,
        });
      },
    },
    // {
    //   label: "Pedido Médico",
    //   action: function() {
    //     router.push({
    //       path: RoutesPaths.virtualOffice.rootName,
    //       replace: true,
    //     });
    //   },
    // },
    {
      label: "PEP",
      featureName: "VIRTUAL_PEP_MENU",
      action: function () {
        removeNewIfLastLevel(this.featureName)
        window.open(LinkType.PEP, "_blank");
      },
    },
    {
      label: "Hub agendamento",
      featureName: "VIRTUAL_HUB_MENU",
      action: function () {
        removeNewIfLastLevel(this.featureName)
        window.open(LinkType.HUB, "_blank");
      },
    },
    {
      label: "Registro Eletrônico de Saúde RES",
      featureName: "VIRTUAL_RES_MENU",
      action: function () {
        removeNewIfLastLevel(this.featureName)
        window.open(LinkType.RES_ELECTRONIC_HEALTH_RECORD, "_blank");
      },
    },
    {
      label: "SGU - CARD",
      featureName: "VIRTUAL_SGU_CARD_MENU",
      action: function () {
        removeNewIfLastLevel(this.featureName)
        window.open(LinkType.SGU_CARD, "_blank");
      },
    },
    /**
    {
      label: "Autorizador do SGU card*",
      action: function() {
        router.push({ path: RoutesPaths.register.taxLicense(), replace: true });
      },
    },
     */
  ];
};

export const dropdownBeneficios = () => {
  return [
    {
      label: "Plano de saúde do Cooperado",
      featureName: "BENEFIT_HEALTH_PLAN_MENU",
      action: function () {
        removeNewIfLastLevel(this.featureName)
        router.push({
          path: RoutesPaths.benefit.healthPlan(),
          replace: true,
        });
      },
    },
    {
      label: "Solicitar Benemérito",
      featureName: "BENEFIT_BENEFACTOR_MENU",
      action: function () {
        removeNewIfLastLevel(this.featureName)
        router.push({
          path: RoutesPaths.benefit.benefactor(),
          replace: true,
        });
      },
    },
    {
      label: "Solicitar Afastamento ",
      featureName: "BENEFIT_ABSENCE_MENU",
      action: function () {
        removeNewIfLastLevel(this.featureName)
        router.push({
          path: RoutesPaths.benefit.absence(),
          replace: true,
        });
      },
    },
    {
      label: "Auxílio Funeral",
      featureName: "BENEFIT_FUNERAL_ASSISTANCE_MENU",
      action: function () {
        removeNewIfLastLevel(this.featureName)
        router.push({
          path: RoutesPaths.benefit.funeralAssistance(),
          replace: true,
        });
      },
    },
    {
      label: "Auxílio Temporário",
      featureName: "BENEFIT_TEMPORARY_ASSISTANCE_MENU",
      action: function () {
        removeNewIfLastLevel(this.featureName)
        router.push({
          path: RoutesPaths.benefit.temporaryAssistance(),
          replace: true,
        });
      },
    },
    {
      label: "Meu Clube Unimed",
      featureName: "BENEFIT_CLUB_MENU",
      action: function () {
        removeNewIfLastLevel(this.featureName)
        window.open(LinkType.CLUB, "_blank");
      },
    },
  ];
};

export const dropdownFinanceiro = () => {
  return [
    {
      label: "Analítica",
      featureName: "FINANCIAL_ANALYTICS_MENU",
      action: function () {
        removeNewIfLastLevel(this.featureName)
        router.push({
          path: RoutesPaths.financial.analytics(),
          replace: true,
        });
      },
    },
    {
      label: "Backbeat",
      featureName: "FINANCIAL_BACKBEAT_MENU",
      action: function () {
        removeNewIfLastLevel(this.featureName)
        showBackbeat();
      },
    },
    {
      label: "Recurso de Glosas",
      featureName: "FINANCIAL_REVIEW_GLOSSARY_MENU",
      action: function () {
        removeNewIfLastLevel(this.featureName)
         router.push({
          path: RoutesPaths.financial.reviewGlosses(),
          replace: true,
        });
      },
    },
    {
      label: "Histórico de Notas Fiscais",
      featureName: "FINANCIAL_INCOME_HISTORY_MENU",
      action: function () {
        removeNewIfLastLevel(this.featureName)
        router.push({
          path: RoutesPaths.financial.incomeHistory(),
          replace: true,
        });
      },
    },
    {
      label: "Informe de Rendimento",
      featureName: "FINANCIAL_REPORT_HISTORY_MENU",
      action: function () {
        removeNewIfLastLevel(this.featureName)
        router.push({
          path: RoutesPaths.financial.reportIncome(),
          replace: true,
        });
      },
    },
    {
      label: "Fundo Sustentabilidade",
      featureName: "FINANCIAL_SUSTAINABILITY_MENU",
      action: function () {
        removeNewIfLastLevel(this.featureName)
        router.push({
          path: RoutesPaths.financial.sustainabilityFund(),
          replace: true,
        });
      },
    },
    {
      label: "Cota Capital",
      featureName: "FINANCIAL_QUOTA_STATEMENT_MENU",
      action: function () {
        removeNewIfLastLevel(this.featureName)
        router.push({
          path: RoutesPaths.financial.quotaStatement(),
          replace: true,
        });
      },
    },
    {
      label: "Relatório de Glosas Voxis",
      featureName: "FINANCIAL_REPORT_VOXIS_MENU",
      action: function () {
        removeNewIfLastLevel(this.featureName)
        router.push({
          path: RoutesPaths.financial.reportVoxis(),
          replace: true,
        });
      },
    },
  ];
};

export const dropdownContentTrainingManagement = () => {
  return [
    {
      label: "Gestão de treinamentos",
      featureName: "CONTENT_MANAGEMENT_TRAINING_MENU",
      action: function () {
        removeNewIfLastLevel(this.featureName)
        router.push({
          path: RoutesPaths.trainingManagement.rootName(),
          replace: true,
        });
      },
    },
    {
      label: "Gestão de certificados",
      featureName: "CONTENT_MANAGEMENT_CERTIFICATION_MENU",
      action: function () {
        removeNewIfLastLevel(this.featureName)
        router.push({
          path: RoutesPaths.certificationManagement.rootName(),
          replace: true,
        });
      },
    },
  ];
};

export const dropdownContentManagement = () => {
  return [
    {
      label: "Gerenciamento de arquivos",
      featureName: "CONTENT_MANAGEMENT_FILE_MENU",
      action: function () {
        removeNewIfLastLevel(this.featureName)
        router.push({
          path: RoutesPaths.fileManagement.rootName(),
          replace: true,
        });
      },
    },
    {
      visible: false,
      label: "Gestão de notificações",
      featureName: "CONTENT_MANAGEMENT_NOTIFICATION_MENU",
      action: function () {
        removeNewIfLastLevel(this.featureName)
        router.push({
          path: "",
          replace: true,
        });
      },
    },
  ];
};

export const dropdownViewsReport = () => {
  return [];
};

