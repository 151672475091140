<template>
  <div>
    <TableVisualizationLayout
      :leftInfo="leftInfo"
      :leftAction="leftAction"
      :showTable="false"
    >
      <template slot="headerContent">
        <div class="row">
          <div class="col-12 w-100">
            <p class="pb-5">
              Selecione o período e clique no botão "download" para baixar o
              relatório de visualizações.
            </p>
          </div>
        </div>
        <b-container id="form-filter-1" fluid="sm">
          <CustomDivDownload>
            <template slot="periodOne">
              <div class="h-100 w-100 d-flex align-items-center">
                <div class="p3">PERÍODO:</div>
                <div class="custom-div-start-date">
                  <date-picker
                    datePickerId="startDate"
                    :key="1"
                    :dataValue="startDate"
                    @datePickerChangedValue="onChangedStartDate"
                    :required="true"
                  />
                </div>
              </div>
            </template>
            <template slot="periodTwo">
              <div class="h-100 w-100 d-flex align-items-center">
                <div class="p3">ATÉ:</div>
                <div class="custom-div-end-date">
                  <date-picker
                    datePickerId="endDate"
                    :key="2"
                    :dataValue="endDate"
                    @datePickerChangedValue="onChangedEndDate"
                    :required="true"
                  />
                </div>
              </div>
            </template>
            <template slot="buttonSearch">
              <b-button
                class="button-custom"
                variant="primary"
                @click="onGetReport()"
                :disabled="disableButton"
              >
                Download
              </b-button>
            </template>
          </CustomDivDownload>
        </b-container>
      </template>
    </TableVisualizationLayout>
  </div>
</template>

<script>
import TableVisualizationLayout from "../../layouts/TableVisualizationLayout.vue";
import DatePicker from "../../components/date-picker/DatePicker.vue";
import FirebaseClient from "../../services/firebase/db-firebase";
import {
  collection,
  query,
  orderBy,
  where,
  getDocs,
} from "firebase/firestore/lite";
import { getFunctionalityName } from "./functionality-mapping.js";
import { createToast } from "../../components/toast/toast";
import { inject } from "@vue/composition-api";
import CustomDivDownload from "./CustomDivDownload.vue";

export default {
  setup() {
    const globalLoading = inject("globalLoading");

    return { globalLoading };
  },
  components: { TableVisualizationLayout, DatePicker, CustomDivDownload },
  mounted() {},
  data() {
    return {
      leftAction: {
        text: "Relatório de visualizações do Portal",
        onClick: this.leftActionClickHandler,
      },
      leftInfo: "",
      loading: true,
      startDate: null,
      endDate: null,
      disableButton: true,
    };
  },
  methods: {
    async onGetReport() {
      try {
        this.globalLoading = true;

        await this.generateReport();

        this.globalLoading = false;
      } catch (error) {
        createToast("Erro", "Não foi possível gerar o relatório.");

        this.globalLoading = false;
      }
    },
    leftActionClickHandler() {
      this.$router.go(-1);
    },
    onChangedStartDate(value) {
      this.startDate = value;
      this.checkValidDates();
    },
    onChangedEndDate(value) {
      this.endDate = value;
      this.checkValidDates();
    },
    checkValidDates() {
      if (!this.startDate) return true;

      if (!this.endDate) return true;

      const initialDate = new Date(this.startDate);
      const finishDate = new Date(this.endDate);

      if (initialDate <= finishDate) this.disableButton = false;
      else this.disableButton = true;
    },
    async generateReport() {
      const db = FirebaseClient.getInstance();

      const querySnapshot = await getDocs(
        query(
          collection(db, "logActivities"),
          where("time", ">", new Date(this.startDate + " 00:00:00 UTC")),
          where("time", "<", new Date(this.endDate + " 23:59:59 UTC")),
          orderBy("time")
        )
      );

      if (querySnapshot.size == 0) {
        createToast(
          "Informação",
          "Não há dados para o período informado.",
          "info"
        );
        return;
      }

      var csvContent = await this.getLogs(querySnapshot);

      if (!csvContent) {
        createToast(
          "Informação",
          "Não há dados para o período informado.",
          "info"
        );
        return;
      }

      const fileName = `relatorio_${this.startDate}_${this.endDate}.csv`;
      const encodedUri = encodeURI(csvContent);
      const link = document.createElement("a");
      link.setAttribute("href", encodedUri);
      link.setAttribute("download", fileName);
      document.body.appendChild(link);

      link.click();
      document.body.removeChild(link);
    },
    async getLogs(querySnapshot) {
      let csvContent = "data:text/csv;charset=utf-8,";
      const header = `Data,Hora,CRM,Dispositivo,Acessou`;
      csvContent += header + "\r\n";
      let filteredCount = 0;

      for (var i = 0; i < querySnapshot.size; i++) {
        const doc = querySnapshot.docs[i];

        const log = doc.data();

        if (log.isError != undefined && log.isError == true) continue;

        if (log.isAdmin != undefined && log.isAdmin == true) continue;

        if (log.crm && log.crm != "100000") {
          const logDate = log.time.toDate();
          const description = getFunctionalityName(log.info);
          if (!description) {
            console.log(log.info);
          }

          const device = log.isApp ? "Mobile" : "Desk";
          const csvRow = `${this.formatDateUtc(logDate)},${this.formatTimeUtc(
            logDate
          )},${log.crm},${device},${description}`;
          csvContent += csvRow + "\r\n";
          filteredCount++;
        } else {
          console.log(log);
        }
      }

      return filteredCount > 0 ? csvContent : "";
    },
    formatDateUtc(date) {
      return (
        date
          .getUTCDate()
          .toString()
          .padStart(2, "0") +
        "/" +
        (date.getUTCMonth() + 1).toString().padStart(2, "0") +
        "/" +
        date.getUTCFullYear()
      );
    },
    formatTimeUtc(date) {
      return (
        date
          .getUTCHours()
          .toString()
          .padStart(2, "0") +
        ":" +
        date
          .getUTCMinutes()
          .toString()
          .padStart(2, "0") +
        ":" +
        date
          .getUTCSeconds()
          .toString()
          .padStart(2, "0")
      );
    },
  },
};
</script>

<style lang="scss" scoped>
#form-filter {
  .filter-row {
    height: 40px;
    line-height: 30px;
  }
}

.button-custom {
  height: 3rem;
  width: 8rem;
  padding: 0;
}

button.button-custom {
  font-size: small;
}

.custom-div-start-date {
  max-width: 100%;
  display: grid;
  grid-column: 0;
  grid-template-columns: 180px auto;
  text-transform: uppercase;
}

.custom-div-end-date {
  max-width: 100%;
  display: grid;
  grid-template-columns: 208px auto;
  text-transform: uppercase;

  @media (min-width: 700px) {
    grid-template-columns: 180px auto;
  }
}
</style>
