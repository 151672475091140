export const RoleStructure = {
  AUTHORITY_USER_IS_CUSTOMER: [
    "INSTITUTIONAL_PAGE_MENU",
    "HOME_MENU",
    "DOCUMENTS_MENU",
    "REGISTER_MENU",
    "VIRTUAL_OFFICE_MENU",
    "ANALYTICS_MENU",
    "DECLARATIONS_MENU",
    "FINANCIAL_MENU",
    "BENEFIT_MENU",
    "REQUEST_HISTORY_MENU",
    "USE_TERM_MENU",
    "FAQ_MENU",
    "CONTENT_MANAGEMENT_MENU",
  ],
  AUTHORITY_SYSTEM: ["FILE_MANAGEMENT_MENU", "HOME_EMPTY", "CONTENT_MANAGEMENT_MENU", "REPORTS_MENU", "CONTENT_TRAINING_MANAGEMENT_MENU"],
  AUTHORITY_ADMIN: ["FILE_MANAGEMENT_MENU", "HOME_EMPTY", "CONTENT_MANAGEMENT_MENU", "REPORTS_MENU", "CONTENT_TRAINING_MANAGEMENT_MENU"],
  AUTHORITY_USER_IS_NOT_CUSTOMER: [],
};
