<template>
  <div id="div-video" v-if="$props.item && $props.item.type == 'VIDEO'">
    <div class="container">
      <div class="row">
        <div class="col-sm">
          <div class="div-video-play div-video-play--size">
            <video
              id="myVideo"
              class="div-video-play--size"
              width="320"
              height="240"
              :key="$props.item.id"
              controls
              @ended="() => onFinishedVideo($props.item)"
              @play="() => onPlay($props.item)"
            >
              <source :src="getLink($props.item)" type="video/mp4" />
            </video>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-sm">
          <b-button
            v-show="$props.item && $props.item.lastStep == false"
            :disabled="$props.item && $props.item.status != 'FINISHED'"
            @click="() => onClickTestKnowledge($props.item)"
            variant="primary"
          >
            Já assisti! Ir para próxima etapa
          </b-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["onFinishedStep", "onStartStep", "onNextStep", "item"],

  components: {},
  data() {
    return {};
  },
  methods: {
    getLink(step) {
      return step.filesAdditional.filter((fa) => fa.principal)[0]?.link;
    },
    async onFinishedVideo(step) {
      await this.$props.onFinishedStep(step, true);
      this.$props.item.status = "FINISHED";
    },
    async onPlay(step) {
      this.stepSelected = step;
      await this.$props.onStartStep(step);
    },
    async onClickTestKnowledge(step) {
      this.stepSelected = step;
      await this.$props.onFinishedStep(step, true);
      await this.$props.onNextStep();
    },
  },
};
</script>
<style lang="scss" scoped>
.div-video-play {
  background-color: white;
  margin-bottom: 2rem;

  &--size {
    width: 100%;
    height: 30rem;

    @media (min-width: 992px) {
      height: 40rem;
    }
  }
}
</style>
