import store from "../../store";

const pathIfAdmin = (path) => store.getters.isAdmin ? "/admin" + path : path;

const RoutesPaths = {
  app: {
    loadApp: () => pathIfAdmin("/load-app"),
  },
  emptyHome: {
    rootName: () => pathIfAdmin("/empty-home"),
  },
  home: {
    rootName: () => pathIfAdmin("/home"),
  },
  register: {
    rootName: () => pathIfAdmin( "/register"),
    taxLicense: () => pathIfAdmin("/register/tax-license"),
    paymentOptions: () => pathIfAdmin("/register/payment-options"),
    attendencePlace: () => pathIfAdmin("/register/attendence-place"),
    medicalSpecialities: () => pathIfAdmin("/register/medical-specialities"),
    personalInformation: () => pathIfAdmin("/register/personal-information"),
  },
  documents: {
    rootName: () => pathIfAdmin("/documents"),
  },
  declarations: {
    rootName: () => pathIfAdmin("/declarations"),
    cooperativeDeclaration: () => pathIfAdmin("/declarations/cooperative-declaration"),
    incomeHistory: () => pathIfAdmin("/declarations/income-history"),
    inss: () => pathIfAdmin("/declarations/inss"),
    iss: () => pathIfAdmin("/declarations/iss"),
    ltcatpp: () => pathIfAdmin("/declarations/ltcatpp"),
    others: () => pathIfAdmin("/declarations/others"),
  },
   benefit: {
    rootName: () => pathIfAdmin("/benefit"),
    healthPlan: () => pathIfAdmin("/benefit/health-plan"),
    benefactor: () => pathIfAdmin("/benefit/benefactor"),
    funeralAssistance: () => pathIfAdmin("/benefit/funeral-assistance"),
    temporaryAssistance: () => pathIfAdmin("/benefit/temporary-assistance"),
    absence: () => pathIfAdmin("/benefit/absence"),
    otherSolicitation: () => pathIfAdmin("/benefit/other-solicitation"),
  },
  financial: {
    rootName: () => pathIfAdmin("/financial"),
    analytics: () => pathIfAdmin("/financial/analytics"),
    reportIncome: () => pathIfAdmin("/financial/report-income"),
    quotaStatement: () => pathIfAdmin("/financial/quota-statement"),
    sustainabilityFund: () => pathIfAdmin("/financial/sustainability-fund"),
    incomeHistory: () => pathIfAdmin("/financial/income-history"),
    backbeat: () => pathIfAdmin("/financial/backbeat"),
    backbeatKnowMore: () => pathIfAdmin("/financial/backbeat-know-more"),
    reviewGlosses: () => pathIfAdmin("/financial/review-glosses"),
    reportVoxis: () => pathIfAdmin("/financial/report-voxis"),
  },
  contentManagement: {
    rootName: () => pathIfAdmin("/content-management"),
  },
  contentTrainingManagement: {
    rootName: () => pathIfAdmin("/content-training-management"),
  },
  faq: {
    rootName: () => pathIfAdmin("/faq"),
  },
  requestHistory: {
    rootName: () => pathIfAdmin("/request-history"),
  },
  useTerm: {
    rootName: () => pathIfAdmin("/use-term"),
    accepted: () => pathIfAdmin("/use-term/accepted"),
  },
  fileManagement: {
    rootName:() => pathIfAdmin("/file-management"),
  },
  trainingManagement: {
    rootName: () => pathIfAdmin("/training-management"),
    form: (id) => pathIfAdmin("/training-management-form/" + (id || ""))
  },
  certificationManagement: {
    rootName: () => pathIfAdmin("/certification-management"),
    form: (id) => pathIfAdmin("/certification-management/" + (id || ""))
  },
  reportParticipation: {
    rootName:() => pathIfAdmin("/report-participation"),
  },
  reports: {
    rootName:() => pathIfAdmin("/portal-views-report"),
  },
  login: {
    rootName: () => pathIfAdmin("/login"),
  },
  virtualOffice: {
    rootName: () => pathIfAdmin("/virtual-office"),
  },
  institutionalPage: {
    rootName: () => pathIfAdmin("/institutional-page"),
    panelEconomic: () => pathIfAdmin("/panel-economic"),
  },
  documentsAtasPage: {
    rootName: () => pathIfAdmin("/institutional-page"),
    documentsAtas: () => pathIfAdmin("/documents-atas"),
  },
  passwordRecovery: {
    rootName: () => pathIfAdmin("/password-recovery"),
    change: () => pathIfAdmin("/password-recovery/change/:id"),
    sucess: () => pathIfAdmin("/password-recovery/sucess"),
  },
  trainingPage: {
    rootName: () => pathIfAdmin("/training-page"),
  },
};

export default RoutesPaths;
