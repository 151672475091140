import ContentTrainingManagement from "../../modules/content-training-management/views/ContentTrainingManagement.vue";
import RouterViewRender from "../../utils/routes/router-view-render";

const ContentTrainingManagementRoutes = {
  path: "",
  component: RouterViewRender,
  meta: {
    requiresAuth: true,
  },
  children: [
    {
      path: "content-training-management",
      component: ContentTrainingManagement,
      meta: {
        requiresAuth: true,
      },
    },
  ],
};

export default ContentTrainingManagementRoutes;
