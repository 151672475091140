<template>
  <div
    class="align-step-center"
    v-if="$props.item && $props.item.type == 'FORM'"
  >
    <b-card class="small-card-history mb-4" border-variant="light">
      <div class="custom-access-form">
        <div class="img-container">
          <img
            class="custom-img-access-form"
            src="../../../assets/images/MDI-file-question-outline.svg"
          />
        </div>
        <div
          @click="() => onStepDownloadFormClick(item)"
          class="d-flex align-items-center cursor-point T14"
        >
          Clique aqui para ter acesso ao formulário com as questões          
        </div>
      </div>
    </b-card>
  </div>
</template>

<script>
export default {
  props: ["onFinishedStep", "onStartStep", "onNextStep", "item"],
  components: {},
  watch: {
    item: {
      handler(val) {
        if (val?.type == "FORM") {
          this.$props.onStartStep(val);
        }
      },
      deep: true,
    },
  },
  data() {
    return {};
  },
  methods: {
    async onStepDownloadFormClick(step) {
      this.stepSelected = step;
      this.openForm(step.link);
      await this.$props.onFinishedStep(step, true);
      this.$props.item.status = "FINISHED";
      await this.$props.onNextStep();
    },
    openForm(link) {
      var a = document.createElement("a");
      a.href = link;
      a.setAttribute("target", "_blank");
      a.click();
    }
  },
};
</script>
<style lang="scss" scoped>
.custom-access-form {
  display: flex;
}
.align-step-center {
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
}
.small-card-history.card {
  width: 25rem;
  justify-content: center;
  align-items: center;
  align-content: center;
  border-radius: 2rem;
  cursor: pointer;
  border-style: solid;

  & > .card-body {
    text-align: center;
    font-weight: 600;
  }

  h5 {
    color: #343a40;
  }

  &:hover {
    transition: color 0.25s ease;
    border-color: var(--primary) !important;
    box-shadow: 5px 5px 6px #6464643d;
  }
}
</style>
