<template>
  <div class="page-container">
    <div>
      <section class="page-header">
        <h2 class="font-weight-bold">
          Treinamentos
        </h2>
      </section>

      <div class="separator-line"></div>
      <section class="sub-header">
        <p class="color-green-2 p3">
          Faça a gestão de treinamentos para os Cooperados.
        </p>
      </section>
    </div>
    <section>
      <div class="card-container">
        <CustomInfoCard
          class="deck deck-margin"
          v-for="card in getCards"
          :key="card.id"
          :onCardClick="() => onClickCard(card)"
          :headerIcon="card.headerIcon"
          :title="card.header"
          :description="card.description"
          :icon="card.icon"
          :featureName="card.featureName"
        />
      </div>
    </section>
  </div>
</template>

<script>
import CustomInfoCard from "../../../components/custom-info-card/CustomInfoCard.vue";
import RoutesPaths from "../../../router/router-structure/routes-paths";
import { isMobile } from "../../../utils/mobile/mobile";

export default {
  components: {
    CustomInfoCard,
  },
  computed: {
    getCards: () => {
      let cards = [
      {
          id: 0,
          icon: "MDI-lightbulb-outline.svg",
          header: "Gestão de treinamentos",
          description:
            "Edite treinamentos e adicione novos treinamentos para os Cooperados",
          route: RoutesPaths.trainingManagement.rootName(),
          showMobile: true,
          featureName: "HOME_CARD_CONTENT_MANAGEMENT_TRAINING",
        },
        {
          id: 1,
          icon: "MDI-certificate.svg",
          header: "Gestão de certificados",
          description:
            "Gerencie os certificados dos Treinamentos",
          route: RoutesPaths.certificationManagement.rootName(),
          showMobile: true,
          featureName: "HOME_CARD_CONTENT_MANAGEMENT_CERTIFICATION",
        },
      ];
      if (isMobile()) {
        return cards.filter((c) => c.showMobile);
      }
      return cards;
    },
  },
  methods: {
    isMobile: isMobile,
    onClickCard({ route, link }) {
      if (link) {
        window.open(link, "_blank");
      } else if (route) this.$router.push(route);
    },
  },
};
</script>

<style lang="scss" scoped>
.deck-margin {
  margin: 2rem;
}

.page-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.page-header {
  text-align: center;
}

.sub-header {
  max-width: 83rem;
  margin: 0 auto;
  margin-top: 1.5rem;
  text-align: center;
}

.card-container {
  max-width: 100rem;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  @include media-breakpoint-up(xs) {
    justify-content: center;
  }

  @include media-breakpoint-up(md) {
    justify-content: center;
  }
}

.pointer {
  cursor: pointer;
}
</style>

