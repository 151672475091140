import { BlipChat } from "blip-chat-widget";
import {
  APP_BLIP_CHAT_APP_KEY,
  APP_BLIP_CHAT_URL,
  APP_USER_PASSWORD_BLIP_CHAT,
} from "../../services/urls/enviroment-variables";
import store from "../../store";
import { isMobile } from "../../utils/mobile/mobile";

//Retorna a mesma instacia criada (Singleton)
var BlipClient = (function() {
  var instance;

  function createInstance() {
    if (isMobile()) return

    var customStyle = isMobile() ? `#blip-chat-container {display: none}` : ''

    return new BlipChat()
      .withAppKey(APP_BLIP_CHAT_APP_KEY)
      .withButton({ 
        color :"#1D6A07", 
        icon:"https://blipmediastore.blob.core.windows.net/public-medias/Media_e5b21bef-ec20-48a1-a285-c8c7847dccd6"
      })
      // .withoutHistory()
      .withAuth({
        authType: BlipChat.DEV_AUTH,
        userIdentity: store.getters.getUserName,
        userPassword: APP_USER_PASSWORD_BLIP_CHAT,
      })
      .withCustomStyle(customStyle)
      .withCustomCommonUrl(APP_BLIP_CHAT_URL);
  }

  return {
    getInstance: function () {
      if (!instance) {
        instance = createInstance();
      }
      return instance;
    },
  };
})();

export default BlipClient;
